export default {
	global: {
		loading: "Kraunasi...",
		wait: "Palaukite",
		add: "Pridėti",
		save: "Išsaugoti",
		cancel: "Atšaukti",
		logout: "Atsijungti",
	},
	login: {
		login: "Prisijungti",
		username: "Vartotojo vardas",
		password: "Slaptažodis",
		showPassword: "Rodyti slaptažodį",
		loggingIn: "Jungiamasi...",
		errors: {
			"Auth.form.error.invalid": "Neteisingas vartotojo vardas arba slaptažodis.",
			"Auth.form.error.password.provide": "Įveskite slaptažodį.",
			"Auth.form.error.email.provide": "Įveskite vartotojo vardą.",
			"Auth.form.error.ratelimit": "Pasiektas prisijungimų limitas. Prašome bandyti vėliau.",
			"Auth.form.error.blocked": "Vartotojas užblokuotas.",
			"NetworkError": "Ryšio klaida.",
			"TokenExpired": "Baigėsi sesijos galiojimo laikas. Prisijunkite iš naujo."
		}
	},
	password: {
		changePassword: "Keisti slaptažodį",
		oldPassword: "Slaptažodis",
		newPassword: "Naujas slaptažodis",
		newPasswordConfirm: "Patvirtinkite naują slaptažodį",
		change: "Keisti",
		changeError: {
			"Auth.form.error.invalid": "Neteisingas vartotojo slaptažodis.",
			"Auth.form.error.match": "Nauji slaptažodžiai nesutampa.",
			"Auth.form.error.shortPassword": "Neįvestas nė vienas simbolis."
		},
	},
	displays: {
		status: "Būsena:",
		updatedAt: "Atnaujinta:",
		notOnlineSince: "Nuo {time}",
		lastOnline: "Nėra duomenų nuo:",
		displays: "Švieslentės",
		assignedDisplays: "Švieslentės: {assignedDisplays}",
		stopsDisplays: "Stotelių švieslentės",
		activeMessages: "Aktyvūs pranešimai",
		noActiveMessages: "Aktyvių pranešimų nėra.",
		noData: "Duomenų nėra.",
		dataUnavailable: "Duomenys laikinai nepasiekiami.",
	},
	messages: {
		validMessages: "Galiojantys pranešimai",
		allMessages: "Visi pranešimai",
		messages: "Pranešimai",
		addNew: "Pridėti naują",
		deleteMessage: "Ištrinti pranešimą",
		createNewMessage: "Pridėti naują pranešimą",
		editMessage: "Redaguoti pranešimą",
		message: "Pranešimas",
		validFrom: "Galioja nuo",
		validTo: "Galioja iki",
		validFromTo: "Galioja nuo {from} iki {to}",
		notAssigned: "Pranešimas nepriskirtas nei vienai švieslentei",
		chooseStops: "Pasirinkite stoteles",
		assignToAll: "Priskirti visoms stotelėms",
		dateFormat: "YYYY-MM-DD HH:MM:SS",
		errors: {
			
		}
	}
}