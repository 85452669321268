import { useEffect, useState } from "react";

const initialState = displays => ({
	message: "",
	displays,
	valid_from: null,
	valid_to: null,
	allDisplays: true,
});

function useMessageForm(displays, messages, params) {
	const [form, setForm] = useState(initialState(displays?.map(d => d.id)));
	const [validFrom, setValidFrom] = useState(new Date());
	const [validTo, setValidTo] = useState(new Date());

	useEffect(() => {
		if (params.id) {
			const message = messages?.find(msg => msg.id === parseInt(params.id, 10)); 
			setForm(form => ({
				...form,
				message: message?.message,
				displays: message?.displays?.map(d => d.id),
				allDisplays: message?.displays?.length === displays?.length,
			}));
			setValidFrom(new Date(message?.valid_from));
			setValidTo(new Date(message?.valid_to));
		}
	}, [displays, messages, params.id]);
	
	useEffect(() => {
		setForm(form => ({
			...form,
			valid_from: validFrom,
			valid_to: validTo,
		}));
	}, [validFrom, validTo]);

	return { form, setForm, validTo, validFrom, setValidFrom, setValidTo };
}

export default useMessageForm;